import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _50d6cecc = () => interopDefault(import('..\\pages\\disclaimer.vue' /* webpackChunkName: "pages_disclaimer" */))
const _04623659 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _0a8bc612 = () => interopDefault(import('..\\pages\\have-a-card.vue' /* webpackChunkName: "pages_have-a-card" */))
const _e81e13a0 = () => interopDefault(import('..\\pages\\have-a-card\\index.vue' /* webpackChunkName: "pages_have-a-card_index" */))
const _620ee8d4 = () => interopDefault(import('..\\pages\\have-a-card\\enrollment.vue' /* webpackChunkName: "pages_have-a-card_enrollment" */))
const _7f00da32 = () => interopDefault(import('..\\pages\\have-a-card\\rejection.vue' /* webpackChunkName: "pages_have-a-card_rejection" */))
const _1257993e = () => interopDefault(import('..\\pages\\have-a-card\\success.vue' /* webpackChunkName: "pages_have-a-card_success" */))
const _db7de9a6 = () => interopDefault(import('..\\pages\\member-number.vue' /* webpackChunkName: "pages_member-number" */))
const _627b53b8 = () => interopDefault(import('..\\pages\\need-a-card.vue' /* webpackChunkName: "pages_need-a-card" */))
const _6a68e7ce = () => interopDefault(import('..\\pages\\need-a-card\\eligibility.vue' /* webpackChunkName: "pages_need-a-card_eligibility" */))
const _2723ede6 = () => interopDefault(import('..\\pages\\need-a-card\\insurance.vue' /* webpackChunkName: "pages_need-a-card_insurance" */))
const _53e6b8d6 = () => interopDefault(import('..\\pages\\need-a-card\\rejection.vue' /* webpackChunkName: "pages_need-a-card_rejection" */))
const _c4901e16 = () => interopDefault(import('..\\pages\\patient-information.vue' /* webpackChunkName: "pages_patient-information" */))
const _76ffc6d4 = () => interopDefault(import('..\\pages\\privacy-policy.vue' /* webpackChunkName: "pages_privacy-policy" */))
const _a582b6d8 = () => interopDefault(import('..\\pages\\success.vue' /* webpackChunkName: "pages_success" */))
const _9c84fa92 = () => interopDefault(import('..\\pages\\summary.vue' /* webpackChunkName: "pages_summary" */))
const _3bb5b727 = () => interopDefault(import('..\\pages\\termsandconditions.vue' /* webpackChunkName: "pages_termsandconditions" */))
const _7fd9863a = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _7fe79dbb = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _fff86686 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _ffdc3784 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _96807d0a = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _7ec7cfba = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/disclaimer",
    component: _50d6cecc,
    name: "disclaimer"
  }, {
    path: "/error",
    component: _04623659,
    name: "error"
  }, {
    path: "/have-a-card",
    component: _0a8bc612,
    children: [{
      path: "",
      component: _e81e13a0,
      name: "have-a-card"
    }, {
      path: "enrollment",
      component: _620ee8d4,
      name: "have-a-card-enrollment"
    }, {
      path: "rejection",
      component: _7f00da32,
      name: "have-a-card-rejection"
    }, {
      path: "success",
      component: _1257993e,
      name: "have-a-card-success"
    }]
  }, {
    path: "/member-number",
    component: _db7de9a6,
    name: "member-number"
  }, {
    path: "/need-a-card",
    component: _627b53b8,
    name: "need-a-card",
    children: [{
      path: "eligibility",
      component: _6a68e7ce,
      name: "need-a-card-eligibility"
    }, {
      path: "insurance",
      component: _2723ede6,
      name: "need-a-card-insurance"
    }, {
      path: "rejection",
      component: _53e6b8d6,
      name: "need-a-card-rejection"
    }]
  }, {
    path: "/patient-information",
    component: _c4901e16,
    name: "patient-information"
  }, {
    path: "/privacy-policy",
    component: _76ffc6d4,
    name: "privacy-policy"
  }, {
    path: "/success",
    component: _a582b6d8,
    name: "success"
  }, {
    path: "/summary",
    component: _9c84fa92,
    name: "summary"
  }, {
    path: "/termsandconditions",
    component: _3bb5b727,
    name: "termsandconditions"
  }, {
    path: "/errors/400",
    component: _7fd9863a,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _7fe79dbb,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _fff86686,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _ffdc3784,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _96807d0a,
    name: "errors-500"
  }, {
    path: "/",
    component: _7ec7cfba,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
