export enum PlpDocType {
    Claim = 1,
    Correspondence = 2,
    Income = 3,
}

export enum PlpEnrollmentCommunicationTypes {
    Mail = 0,
    Fax = 1,
    Vmail = 2,
    Phone = 3,
    Email = 4,
    Feed = 5,
    Void = 6,
    Message = 7,
    Guardian = 8,
    Text = 9,
}

export enum PlpSiteCommunicationTypes {
    Mail = 0,
    Fax = 1,
    Email = 4,
}

export enum PlpSiteType {
    Facility = 1,
    Infusion = 2,
    Pharmacy = 3,
}

// export interface PatientInformationModule {
//     patientInformation: PatientInformationData;
//     updatePatientInformation(data: PatientInformationData): void;
// }

export interface PlpSurveyQuestionAnswer {
    surveyId: number;
    questionId: number;
    questionText: string;
    answerKey: number | null;
    answerText: string | null;
}

export enum PlpPaymentType {
    CheckToPatient = 2,
    Pharmacy = 8,
}