var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inner-footer"},[_c('div',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],style:({ 'min-height': (_vm.aboveTheFold ? _vm.placeHolder.h : 0) + 'px' }),attrs:{"id":"footer-placeholder","color":"transparent"}}),_vm._v(" "),(_vm.iri.visibility)?_c('div',{class:{ 'fixed-footer': _vm.aboveTheFold, 'static-footer': !_vm.aboveTheFold },attrs:{"id":"main-footer"}},[_c('v-flex',{staticClass:"relative",attrs:{"xs12":""}},[_c('v-flex',{staticClass:"collapsed-iri",attrs:{"id":"peeking-iri"}},[_c('v-btn',{ref:"iri-button",class:{ 'fore-front': true, 'shift-up': _vm.aboveTheFold },style:({ 'pointer-events': _vm.aboveTheFold ? 'auto' : undefined }),attrs:{"fab":"","color":"secondary","fixed":"","right":"","bottom":""},on:{"click":function($event){_vm.aboveTheFold
                            ? _vm.$vuetify.goTo('#main-footer', {
                                  duration: 1000,
                                  easing: 'easeInOutCubic',
                                  offset: 0,
                              })
                            : _vm.$vuetify.goTo('#main-layout', {
                                  duration: 1000,
                                  easing: 'easeInOutCubic',
                                  offset: 0,
                              })}}},[_c('fa-icon',{attrs:{"size":"2x","icon":_vm.aboveTheFold ? _vm.$icons.faChevronDown : _vm.$icons.faChevronUp}})],1),_vm._v(" "),_c('iri-info',{staticClass:"pa-2 pb-6 pt-0"})],1),_vm._v(" "),_c('p',{staticClass:"text-center rights"},[_vm._v("© 2019 TrialCard Inc. All rights reserved. Not for Reproduction or Distribution.")])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }