import { Mutation, Action } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { Location } from 'vue-router';
import { Moment } from 'moment';
import { EdgePatientEnrollmentApi } from '@trialcard/apigateway.client';
import { Gender } from '@trialcard/enums';
import { Resolve } from 'vue-di';
import { OnClear } from '~/services/clearModule';
import { clearRecords } from '~/services/clearRecords';

@InjectModule({ stateFactory: true }, module)
@OnClear<EnrollmentModule>(async value => value.clear())
export default class EnrollmentModule extends InjectVuexModule {
    @Resolve
    public patientEnrollmentApi!: EdgePatientEnrollmentApi;

    public haveACard = {
        memberNumber: '',
    };

    @Mutation
    public clear() {
        clearRecords(this.haveACard);
    }

    @Mutation
    public updateHaveACard(data: typeof EnrollmentModule.prototype.haveACard) {
        Object.assign(this.haveACard, data);
    }
}
