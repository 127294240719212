























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';

@Component<IRIInfo>({
    components: {},
    icons: {
        faChevronUp,
    },
})
export default class IRIInfo extends Vue {}
