/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { Mutation, Action } from 'vuex-module-decorators';
import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { EdgePatientEnrollmentApi, EnrollmentExtV2Api, EdgeCouponApi, EdgePlpApi, EdgeDocumentsApi } from '@trialcard/apigateway.client';
import { Gender } from '@trialcard/enums';
import {
    EnrollmentResultModelResponse,
    EnrollmentInsVerRequestModel,
    CouponEnrollmentEnrollmentWithInsuranceProgramIdEnrollmentWithInsuranceResponse,
    SubmitClaimResultModel,
    SubmitClaimResultModelResponse,
    DocumentsV1DocumentUploadUploadResponse,
} from '@trialcard/apigateway.models';
import { AxiosError, AxiosResponse, AxiosInstance, AxiosRequestConfig, AxiosPromise } from 'axios';
import { DocumentFunctions } from '../components/documents/document-functions';
import { PlpDocType, PlpSurveyQuestionAnswer, PlpSiteType, PlpEnrollmentCommunicationTypes, PlpPaymentType } from '~/store/plpEnums';
import { OnClear } from '~/services/clearModule';
import { clearRecords } from '~/services/clearRecords';
import { Settings } from '~/services/settings';
import PatientModule from '~/store/patient';
import { VUFile } from '~/types/componentTypes';
import Document from '~/store/document';

export interface PatientInformationData {
    patientId: string;
    address1: string;
    address2: string;
    gender: string;
    dateOfBirth: string;
    postalCode: string;
    city: string;
    state: string;
    phone: string;
    firstName: string;
    lastName: string;
    email: string;
}

// export interface PatientInformationModule {
//     patientInformation: PatientInformationData;
//     updatePatientInformation(data: PatientInformationData): void;
// }

@InjectModule({ stateFactory: true }, module)
export default class EnrollmentModule extends InjectVuexModule {
    @Resolve
    public edgePlpApi!: EdgePlpApi;

    @Resolve
    public enrollmentApi!: EdgePatientEnrollmentApi;

    @Resolve
    public settings!: Settings;

    @Resolve
    public EdgeCouponApi!: EdgeCouponApi;

    @Resolve
    public documentApi!: EdgeDocumentsApi;

    @Resolve
    public patient!: PatientModule;

    @Resolve
    public documentStore!: Document;

    @Resolve
    public documentFunctions!: DocumentFunctions;

    @Mutation
    public updateSurveySessionId(newId: number) {
        this.surveySessionId = newId;
    }

    alternativeEnrollment: boolean | null = null;

    surveySessionId = 0;

    public demographicInformation = {
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNumber: '',
        dateOfBirth: '',
        gender: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        memberNumber: '',
    };

    public insuranceVerification = {
        insuranceType: 1,
        payerName: '',
        payerMedicalId: '',
    };

    public otherInformation = {
        productPurchased: '',
        daySupply: '',
        quantity: '',
        pharmacyType: '',
        remainingCost: '',
        totalCost: '',
        insuranceCoverage: '',
        prescriptionInsurance: null as boolean | null,
        submissionType: '',
    };

    public signature = '';

    public promoId = null as null | number | undefined;

    public tcProgramId = null as null | number | undefined;

    public survey = [{}];

    private files: VUFile[] = [];

    public receipts = {
        files: [] as Array<{ file: File; docType: PlpDocType }>,
    };

    public docsForDisplay: VUFile[] = [];
    public docsForUpload: VUFile[] = [];

    public products = [];

    @Mutation
    public setProducts(products: any) {
        this.products = products;
    }

    @Mutation
    public clear() {
        clearRecords(this.demographicInformation);
        clearRecords(this.insuranceVerification);
        clearRecords(this.otherInformation);
        clearRecords(this.receipts);
        clearRecords(this.promoId);
        clearRecords(this.signature);
        this.docsForDisplay = [] as VUFile[];
        this.docsForUpload = [] as VUFile[];
    }

    @Mutation
    public copyVuFile(files: VUFile[]) {
        this.files = files;
    }

    @Mutation
    public setMemberNumber(memberNumber: string) {
        this.demographicInformation.memberNumber = memberNumber;
    }

    @Mutation
    public setPromoId(promoId: number | undefined | null) {
        this.promoId = promoId;
    }

    @Mutation
    public updateDemographicInformation(data: typeof EnrollmentModule.prototype.demographicInformation) {
        Object.assign(this.demographicInformation, data);
    }

    @Mutation
    public updateInsuranceVerification(data: typeof EnrollmentModule.prototype.insuranceVerification) {
        Object.assign(this.insuranceVerification, data);
    }

    @Mutation
    public updateOtherInformation(data: typeof EnrollmentModule.prototype.otherInformation) {
        Object.assign(this.otherInformation, data);
    }

    @Mutation
    public updateFiles(data: typeof EnrollmentModule.prototype.receipts) {
        Object.assign(this.receipts, data);
    }

    @Mutation
    public addReimbursementForm(data: any) {
        // this.receipts.files.push(data as any)
        this.docsForUpload.push(data);
    }

    @Mutation
    public updateDocsForDisplay(data: typeof EnrollmentModule.prototype.docsForDisplay) {
        this.docsForDisplay = data;
    }

    @Mutation
    public updateDocsForUpload(data: typeof EnrollmentModule.prototype.docsForUpload) {
        this.docsForUpload = data;
    }

    @Action({})
    public async submit() {}

    async uploadDocument(): Promise<{
        success: boolean;
        messages: string[];
        data: SubmitClaimResultModel[];
    }> {
        const files: Array<{ files: File[]; docType: number }> = [];

        // const mergedFileByteArray = await this.documentFunctions.mergeFiles(this.docsForUpload)
        // const fileBlob = new Blob([mergedFileByteArray.buffer], { type: 'applicaton/pdf'})
        // const file = new File([fileBlob], 'reimbursement-form.pdf', { type: 'application/pdf', lastModified: Date.now() })
        // const mergedPdf = { file, docType: PlpDocType['Claim']}

        // files.push({ files: [mergedPdf.file], docType: mergedPdf.docType })

        // const mergedFile = await this.documentStore.createBlobForMergedFiles('merge', mergedFileByteArray)

        for (const receiptFile of this.docsForUpload) {
            // receiptFile.docType = PlpDocType['Claim'];
            files.push({ files: [receiptFile.file], docType: 1 });
        }

        const uploadDocumentResponse = {
            success: false,
            messages: [] as string[],
            data: [] as SubmitClaimResultModel[],
        };
        try {
            if (files.length > 0) {
                // if the program is veclury calling the upload documents API
                if (this.tcProgramId?.toString() === '1198') {
                    const submitClaimResponsesVeclury = await Promise.all(
                        files.map(async f => {
                            const form = new FormData();
                            form.append('type', '10001');
                            form.append('attachment', f.files[0]);
                            const axios: AxiosInstance = (this.documentApi as any).axios;
                            const axiosRequest: AxiosRequestConfig = {
                                url: 'edge/documents/v1/Document/upload',
                                method: 'POST',
                                data: form,
                                headers: {
                                    'x-program-id': this.settings.programIdForVeclury!,
                                },
                            };
                            await Promise.all([
                                (this.documentApi as any).authentications.ApiKey.applyToRequest(axiosRequest),
                                (this.documentApi as any).authentications.Bearer.applyToRequest(axiosRequest),
                                (this.documentApi as any).authentications.Token.applyToRequest(axiosRequest),
                            ]);
                            return axios.request(axiosRequest) as AxiosPromise<DocumentsV1DocumentUploadUploadResponse>;
                        })
                    );

                    uploadDocumentResponse.success = submitClaimResponsesVeclury.every(res => res.data.data && res.data.success === true);
                } else {
                    // if program is not veclury calling the submit Documents API

                    const submitClaimResponsesGilead = await Promise.all(
                        files.map(async f => {
                            const form = new FormData();
                            form.append('programId', '286');
                            form.append('memberNumber', this.demographicInformation.memberNumber);
                            form.append('body[docType]', f.docType.toString());
                            f.files.map(async x => {
                                form.append('body[files]', x);
                            });
                            const axios: AxiosInstance = (this.EdgeCouponApi as any).axios;
                            const axiosRequest: AxiosRequestConfig = {
                                url: '/edge/coupon/Documents/submitDocuments',
                                method: 'POST',
                                data: form,
                                headers: {
                                    'x-program-id': 286,
                                },
                            };
                            await Promise.all([
                                (this.EdgeCouponApi as any).authentications.ApiKey.applyToRequest(axiosRequest),
                                (this.EdgeCouponApi as any).authentications.Bearer.applyToRequest(axiosRequest),
                                (this.EdgeCouponApi as any).authentications.Token.applyToRequest(axiosRequest),
                            ]);
                            return axios.request(axiosRequest) as AxiosPromise<SubmitClaimResultModelResponse>;
                        })
                    );

                    uploadDocumentResponse.success = submitClaimResponsesGilead.every(res => res.data.data && res.data.success);
                }
            }
            return uploadDocumentResponse;
        } catch (error) {
            return uploadDocumentResponse;
        }
    }

    @Mutation
    public setTcProgramId(tcProgramId: number | undefined | null) {
        this.tcProgramId = tcProgramId;
    }

    // @Action({ rawError: true })
    // public async submitToAPI() {
    //     let response = null;
    //     const { firstName, lastName, dateOfBirth, gender, addresses } = this.patient.patientInformation;
    //     try {
    //         const patient = {
    //             account: {
    //                 patientData: {
    //                     canUpdate: true,
    //                     canInsert: true,
    //                     skipSearch: false,
    //                     model: {
    //                         firstName,
    //                         lastName,
    //                         dateOfBirth,
    //                         gender,
    //                         addresses,
    //                         middleName: '',
    //                         suffix: '',
    //                         nickname: '',
    //                     } as EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    //                 } as UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    //                 accountData: {
    //                     canUpdate: true,
    //                     canInsert: true,
    //                     skipSearch: false,
    //                     model: {
    //                         accountType: 1,
    //                     },
    //                 } as UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
    //                 surveySessionData: [this.alternativeEnrollment ? null : this.surveySessionId] as number[],
    //             } as EnterpriseCoordinatorAccountUpsearchRequestModel,
    //         } as EnrollPatientRequestModel;
    //         response = await this.enrollmentApi.patientEnrollmentEnrollPatient(patient, this.settings.programId);
    //     } catch {
    //         response = { status: 400 } as AxiosResponse;
    //     }
    //     return response;
    // }

    // @Action({ rawError: true })
    // public async fetchSurveySession() {
    //     let surveyError = false;
    //     try {
    //         const surveySessionResponse = await this.surveyAPI.surveySessionCreate0({ surveyId: Number(this.settings.surveyId) });
    //         const surveyId = surveySessionResponse.data.data!.surveySessionId;
    //         const fullSurveySessionResponse = await this.surveyAPI.fullSurveySessionCreate0({
    //             sessionValues: [
    //                 {
    //                     surveySessionId: surveyId,
    //                     questionId: Number(this.settings.cardTypeQuestionId),
    //                     answerId: Number(this.settings.cardTypeAnswerId),
    //                 },
    //             ],
    //         });

    //         this.updateSurveySessionId(Number(surveyId));
    //     } catch (error) {
    //         console.error(error);
    //         surveyError = true;
    //         // TODO: Navigate to error
    //     }
    //     return surveyError;
    // }
}
