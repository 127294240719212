// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("static/background.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".v-application,body,html{font-family:Raleway,sans-serif;background-image:radial-gradient(hsla(0,0%,100%,.9),hsla(0,0%,100%,.75),hsla(0,0%,100%,.25)),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-attachment:fixed;background-size:cover}.v-application ul{margin-bottom:16px}#main-layout{background:none}.instructions{font-size:1.25rem;text-align:center;margin:0 0 20px}.nav-title{font-size:2rem;text-align:right}.nav-subtitle{font-size:1.55rem;text-align:right;font-style:italic}.brand-header-small{font-weight:400;text-align:center;font-size:2.5rem;text-transform:lowercase;margin:20px 0 0}.brand-header{color:#00649c;font-weight:700;font-size:4rem;text-align:center;line-height:1.25;text-transform:uppercase}.v-application a{text-decoration:none;color:#000}.page-enter-active,.page-leave-active{transition:opacity .2s ease-in}.page-enter-active{transition-delay:.3s}.page-enter,.page-leave-to{opacity:0}.v-btn--floating .v-btn__content,.v-btn--large .v-btn__content,.v-btn--small .v-btn__content{height:inherit;width:inherit}.fa-layers,.fa-stack,.v-icon.fa,.v-icon.svg-inline--fa{display:inline-flex}.v-icon.fa-inverse{color:#fff!important}.svg-inline--fa.v-icon--is-component .v-input{height:16px;font-size:16px;width:16px}.v-input--selection-controls__input .svg-inline--fa.v-icon--is-component{height:24px;font-size:24px;width:24px}.big-btn{width:100%;font-weight:700;font-size:2rem}.steps{font-size:1.5rem;font-weight:700}.footer{text-align:center}.footer a{color:#000;font-weight:700}.note{text-align:center;font-size:1rem;margin-top:5px}.next-btn{color:#fff}.page-title{text-align:center;color:#000;font-size:2rem}@media screen and (max-width:1260px){.full-width-breakpoint{display:none;line-height:0}}@media (max-width:720px){.brand-header-small{font-size:1.5rem}.brand-header{font-size:2.5rem}.nav-title{font-size:1rem}.nav-subtitle{font-size:.77rem}.steps{text-align:center}}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#c44230",
	"secondaryColor": "#003c60",
	"accentColor": "#c41230"
};
module.exports = exports;
