import { Mutation, Action } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { SurveyV2Api } from '@trialcard/apigateway.client';
import { toNumber, upperCase } from 'lodash';
import { Container } from 'vue-di';
import { FullSurveyResponseModel, FullSurveyModel } from '~/models/survey';

@InjectModule({ stateFactory: true }, module)
export default class SurveyModule extends InjectVuexModule {
    public surveys: { [key: string]: FullSurveyModel } = {};

    @Action({})
    public async loadFullSurvey(surveyId: string) {
        const api = Container.instance.get(SurveyV2Api);
        const response = await api.fullSurveyGet(surveyId);
        const data: FullSurveyResponseModel = response.data;
        this.storeSurvey(data.data);
    }

    @Action({})
    public loadLocalFullSurvey(surveyId: string) {
        if (this.surveys[surveyId]) return;
        const surveyFiles = require.context('~/assets/surveys/', true, /(\.json)/);
        const surveyData = surveyFiles.keys().map(z => ({ survey: surveyFiles(z) as FullSurveyModel }));
        for (const { survey } of surveyData) {
            this.storeSurvey(survey);
        }
    }

    public getSurveyQuestion(id: string, questionId: string) {
        return this.surveys[id].questionsWithAnswers.find(z => z.questionId === toNumber(questionId)!);
    }

    public getSurveyAnswerByCategoryCode(id: string, questionId: string, answerCategoryCode: string) {
        const question = this.getSurveyQuestion(id, questionId);
        if (question && question.answers) {
            const answer = question.answers.find(answer => upperCase(answer.answerCategoryCode) === upperCase(answerCategoryCode));
            return answer;
        }
        return undefined;
    }

    @Mutation
    public storeSurvey(model: FullSurveyModel) {
        this.surveys[model.surveyId] = model;
    }
}
