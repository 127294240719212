


















import Vue, { RenderContext, CreateElement } from 'vue';
import { multiErrorExtractorMixin } from 'vuelidate-error-extractor';
import Component from 'vue-class-component';

export interface IFieldErrors {
    fieldName: string;
    validationKey: string;
    hasError: boolean;
    params: { attribute: string };
    $dirty: boolean;
    $error: boolean;
    $invalid: boolean;
}

@Component({})
export default class FormSummary extends Vue.extend(multiErrorExtractorMixin) {
    get filteredErrors () {
        return (this.errors as unknown as IFieldErrors[]).filter(x => x.fieldName === 'files')
    }
}
