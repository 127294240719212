import { Mutation } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { OnClear } from '~/services/clearModule';
import { clearRecords } from '~/services/clearRecords';

@InjectModule({ stateFactory: true }, module)
@OnClear<EligibilityModule>(async value => value.clear())
export default class EligibilityModule extends InjectVuexModule {
    public eligibilityAnswers = {
        federalProgram: null as boolean | null,
        USCitizen: null as boolean | null,
        adult: null as boolean | null,
    };

    @Mutation
    public clear() {
        clearRecords(this.eligibilityAnswers);
    }

    @Mutation updateEligibilityInformation(data: typeof EligibilityModule.prototype.eligibilityAnswers) {
        Object.assign(this.eligibilityAnswers, data);
    }
}
