// import { Gender  from '@trialcard/enums';

export default [
    {
        name: 'Authorized Generic of EPCLUSA<sup>\u00AE</sup> (sofosbuvir 400 mg/velpatasvir 100 mg) tablets',
        ndc: '72626-2701-01',
        promoId: [2274, 2303, 2183, 2206],
    },
    {
        name: 'Authorized Generic of HARVONI<sup>\u00AE</sup> (ledipasvir 90 mg/ sofosbuvir 400 mg) tablets',
        ndc: '72626-2601-01',
        promoId: [2274, 2303, 2183, 2206],
    },
    {
        name: 'BIKTARVY<sup>\u00AE</sup> (bictegravir 50 mg, emtricitabine 200 mg, and tenofovir alafenamide 25 mg)',
        ndc: '61958-2501-01',
        promoId: [2247],
    },
    {
        name: 'BIKTARVY<sup>\u00AE</sup> (bictegravir 30 mg/emtricitabine 120 mg/tenofovir alafenamide 15 mg)',
        ndc: '61958-2501-01',
        promoId: [2247],
    },

    { name: 'CAYSTON<sup>\u00AE</sup> (aztreonam for inhalation solution 75 mg/vial)', ndc: '61958-0901-01', promoId: [2249] },
    {
        name: 'COMPLERA<sup>\u00AE</sup> (emtricitabine 200 mg, rilpivirine 25 mg, and tenofovir disoproxil fumarate 300 mg)',
        ndc: '61958-1101-01',
        promoId: [2247],
    },
    { name: 'DESCOVY<sup>\u00AE</sup> (emtricitabine 200 mg and tenofovir alafenamide 25 mg)', ndc: '61958-2002-01', promoId: [2247] },
    { name: 'DESCOVY<sup>\u00AE</sup> (emtricitabine 120 mg/tenofovir alafenamide 15 mg)', ndc: '61958-2002-01', promoId: [2247] },
    { name: 'EMTRIVA<sup>\u00AE</sup> (emtricitabine 200 mg)', ndc: '61958-0602-01', promoId: [2247] },
    { name: 'EPCLUSA<sup>\u00AE</sup> (sofosbuvir 400 mg/velpatasvir 100 mg) tablets', ndc: '61958-2201-01', promoId: [2246, 2256] },
    {
        name: 'GENVOYA<sup>\u00AE</sup> (elvitegravir 150 mg, cobicistat 150 mg, emtricitabine 200 mg, and tenofovir alafenamide 10 mg)',
        ndc: '61958-1901-01',
        promoId: [2247],
    },
    { name: 'HARVONI<sup>\u00AE</sup> (ledipasvir 90 mg/ sofosbuvir 400 mg) tablets', ndc: '61958-1801-01', promoId: [2250, 2257] },
    { name: 'HARVONI<sup>\u00AE</sup> (ledipasvir 45 mg/ sofosbuvir 200 mg) tablets', ndc: '61958-1803-01', promoId: [2250, 2257] },
    { name: 'HARVONI<sup>\u00AE</sup> (ledipasvir 45 mg/ sofosbuvir 200 mg) oral pellets', ndc: '61958-1804-01', promoId: [2250, 2257] },
    { name: 'HARVONI<sup>\u00AE</sup> (ledipasvir 33.75 mg/ sofosbuvir 150 mg) oral pellets', ndc: '61958-1805-01', promoId: [2250, 2257] },
    { name: 'LETAIRIS<sup>\u00AE</sup> (ambrisentan 5 mg, 10 mg) tablets', ndc: '61958-0801-01', promoId: [2271] },
    {
        name: 'ODEFSEY<sup>\u00AE</sup> (emtricitabine 200 mg, rilpivirine 25 mg, and tenofovir alafenamide 25 mg)',
        ndc: '61958-2101-01',
        promoId: [2247],
    },
    { name: 'SOVALDI<sup>\u00AE</sup> (sofosbuvir 400 mg) tablets', ndc: '61958-1501-01', promoId: [2251, 2254] },
    { name: 'SOVALDI<sup>\u00AE</sup> (sofosbuvir 200 mg) tablets', ndc: '61958-1503-01', promoId: [2251, 2254] },
    { name: 'SOVALDI<sup>\u00AE</sup> (sofosbuvir 200 mg) oral pellets', ndc: '61958-1505-01', promoId: [2251, 2254] },
    { name: 'SOVALDI<sup>\u00AE</sup> (sofosbuvir 150 mg) oral pellets', ndc: '61958-1504-01', promoId: [2251, 2254] },
    {
        name:
            'STRIBILD<sup>\u00AE</sup> (elvitegravir 150 mg, cobicistat 150 mg, emtricitabine 200 mg, and tenofovir disoproxil fumarate 300 mg)',
        ndc: '61958-1201-01',
        promoId: [2247],
    },
    {
        name:
            'TRUVADA<sup>\u00AE</sup> (emtricitabine and tenofovir disoproxil fumarate 200 mg/300 mg, 167 mg/250 mg, 133 mg/200 mg, or 100 mg/150 mg)',
        ndc: '61958-0701-01',
        promoId: [2247],
    },
    { name: 'TYBOST<sup>\u00AE</sup> (cobicistat 150 mg)', ndc: '61958-1401-01', promoId: [2247] },
    { name: 'VEMLIDY<sup>\u00AE</sup> (tenofovir alafenamide 25 mg) tablets', ndc: '61958-2301-01', promoId: [2245] },
    {
        name: 'VOSEVI<sup>\u00AE</sup> (sofosbuvir 400 mg/ velpatasvir 100 mg/ voxilaprevir 100 mg) tablets',
        ndc: '61958-2401-01',
        promoId: [2248, 2225],
    },
    { name: 'ZYDELIG<sup>\u00AE</sup> (idelalisib 100 mg, 150 mg)', ndc: '61958-1701-01', promoId: [2244] },
    { name: 'EPCLUSA<sup>\u00AE</sup> (sofosbuvir 200 mg/velpatasvir 50 mg) tablets', ndc: '61958-2203-01', promoId: [2246, 2256] },

    { name: 'EPCLUSA<sup>\u00AE</sup> (sofosbuvir 200 mg/velpatasvir 50 mg) oral pellets', promoId: [2246, 2256] },

    { name: 'EPCLUSA<sup>\u00AE</sup> (sofosbuvir 150 mg/velpatasvir 37.5 mg) oral pellets', ndc: '61958-2203-01', promoId: [2246, 2256] },
    { name: 'VEKLURY<sup>\u00AE</sup> (remdesivir) 100 mg for injection', ndc: '61958-2901-02', promoId: [3500] },
];
