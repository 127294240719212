import colors from 'vuetify/es5/util/colors';
// import { Theme } from 'vuetify/src/services/theme';
import { Theme } from 'vuetify/types/services/theme';

export default {
    dark: false,
    themes: {
        light: {
            primary: colors.blue.lighten2,
            secondary: colors.indigo.lighten2,
            accent: colors.lightBlue.lighten2,
            error: colors.red.lighten2,
            warning: colors.amber.lighten2,
            info: colors.lightBlue.lighten2,
            success: colors.green.lighten2,
        },
        dark: {
            primary: colors.blue.darken2,
            secondary: colors.indigo.darken2,
            accent: colors.deepPurple.darken2,
            error: colors.red.darken2,
            warning: colors.amber.darken2,
            info: colors.lightBlue.darken2,
            success: colors.green.darken2,
        },
    },
} as Theme;
