/* eslint-disable @typescript-eslint/no-explicit-any */
import { PDFDocument, rgb, StandardFonts, PageSizes, PDFImage } from 'pdf-lib';

export class DocumentFunctions {
    public static documentFont = StandardFonts.Helvetica;
    public static fontSize = 16;
    private static pageSize = 1080;

    public static async createPdf(newFile: any) {
        const arrayBuffer = await fetch(newFile.blob).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.create();
        const pdfWithContent = await PDFDocument.load(arrayBuffer);
        const pages = await pdfDoc.copyPages(pdfWithContent, pdfWithContent.getPageIndices());
        // Add all the pages from the document
        pages.forEach(page => {
            pdfDoc.addPage(page);
        });
        const pdfBytes = await pdfDoc.save();
        return pdfBytes;
    }

    public static async convertImageToPdf(newFile: any, type: string) {
        const arrayBuffer = await fetch(newFile.blob).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.create();
        const image = type === 'image/jpeg' ? await pdfDoc.embedJpg(arrayBuffer) : await pdfDoc.embedPng(arrayBuffer);
        const dynamicScale = this.generateDynamicScale(image);
        const imgReduced = image.scale(dynamicScale);
        const page = pdfDoc.addPage([this.pageSize, this.pageSize]);
        page.drawImage(image, {
            x: (page.getWidth() - imgReduced.width) / 2,
            y: (page.getHeight() - imgReduced.height) / 2,
            width: imgReduced.width,
            height: imgReduced.height,
            // x: page.getWidth() / 2 - image.width / 2,
            // y: page.getHeight() / 2 - image.height / 2,
            // width: image.width,
            // height: image.height,
        });
        const pdfBytes = await pdfDoc.save();
        // Serialize the PDFDocument to bytes (a Uint8Array)
        return pdfBytes;
    }

    public static async prependPDF(newFile: any, textObj: string[]) {
        const arrayBuffer = await fetch(newFile.blob).then(res => res.arrayBuffer());

        const pdfDoc = await PDFDocument.create();

        const coverPage = await DocumentFunctions.createCover(textObj);
        const [firstDonorPage] = await pdfDoc.copyPages(coverPage, [0]);
        const secondDonorPdfDoc = await PDFDocument.load(arrayBuffer);

        const secondDonorPages = await pdfDoc.copyPages(secondDonorPdfDoc, secondDonorPdfDoc.getPageIndices());

        // Add the coverpage
        pdfDoc.addPage(firstDonorPage);

        // All all the pages from the document
        secondDonorPages.forEach(page => {
            pdfDoc.addPage(page);
        });

        const pdfBytes = await pdfDoc.save();

        // Serialize the PDFDocument to bytes (a Uint8Array)
        return pdfBytes;
    }

    public async mergeFiles(filesArray: any) {
        const pdfDoc = await PDFDocument.create();

        // put reimbursement form as the beginning of the PDF
        const reimbursementFormIndex = filesArray.length - 1;
        const reimbursementFormArrayBuffer = await fetch(filesArray[reimbursementFormIndex].blob).then(res => res.arrayBuffer());
        const reimbursementFormDoc = await PDFDocument.load(reimbursementFormArrayBuffer);
        const reimbursementFormPages = await pdfDoc.copyPages(reimbursementFormDoc, reimbursementFormDoc.getPageIndices());
        reimbursementFormPages.forEach(page => {
            pdfDoc.addPage(page);
        });

        // add in the rest of the document
        let i: number;
        for (i = 0; i < reimbursementFormIndex; i++) {
            const fileArrayBuffer = await fetch(filesArray[i].blob).then(res => res.arrayBuffer());
            const fileDoc = await PDFDocument.load(fileArrayBuffer);
            const filePages = await pdfDoc.copyPages(fileDoc, fileDoc.getPageIndices());
            filePages.forEach(page => {
                pdfDoc.addPage(page);
            });
        }
        const pdfBytes = await pdfDoc.save();
        return pdfBytes;
    }

    public static async prependImageToPDF(newFile: any, textObj: string[], type: string) {
        const arrayBuffer = await fetch(newFile.blob).then(res => res.arrayBuffer());

        const pdfDoc = await PDFDocument.create();

        const coverPage = await DocumentFunctions.createCover(textObj);

        const image = type === 'image/jpeg' ? await pdfDoc.embedJpg(arrayBuffer) : await pdfDoc.embedPng(arrayBuffer);
        const [firstDonorPage] = await pdfDoc.copyPages(coverPage, [0]);
        // Add the coverpage
        pdfDoc.addPage(firstDonorPage);

        const page = pdfDoc.addPage();

        page.drawImage(image, {
            x: page.getWidth() / 2 - image.width / 2,
            y: page.getHeight() / 2 - image.height / 2,
            width: image.width,
            height: image.height,
        });

        const pdfBytes = await pdfDoc.save();

        // Serialize the PDFDocument to bytes (a Uint8Array)
        return pdfBytes;
    }

    public static async createCover(strings: string[]) {
        const pdfDoc = await PDFDocument.create();
        const font = await pdfDoc.embedFont(DocumentFunctions.documentFont);

        // Add a blank page to the document
        const page = pdfDoc.addPage(PageSizes.Letter);
        const { width, height } = page.getSize();

        // Draw a adds the text to the coverpage
        strings.forEach((item, index) =>
            page.drawText(item, {
                x: 100,
                y: (height * 7) / 8 - 1.5 * DocumentFunctions.fontSize * index,
                size: DocumentFunctions.fontSize,
                font,
                color: rgb(0, 0, 0),
            })
        );

        return pdfDoc;
    }

    public static generateDynamicScale(image: PDFImage): number {
        return image.width > image.height ? (this.pageSize - 80) / image.width : (this.pageSize - 80) / image.height;
    }
}
