/* eslint-disable dot-notation */
import Vue from 'vue';
import { Mutation, Action } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { Resolve } from 'vue-di';
import moment from 'moment';
import { EdgeDocumentsApi } from '@trialcard/apigateway.client/edgeDocumentsApi';
import { PDFDocument } from 'pdf-lib';
import { PlpDocType } from './plpEnums';
import EnrollmentModule from '~/store/enrollment';
import PatientModule from '~/store/patient';
import products from '~/assets/products';

const capitalize = (str: string) =>
    str
        .split(' ')
        .map(str =>
            str
                .charAt(0)
                .toUpperCase()
                .concat(str.slice(1))
        )
        .join(' ');
@InjectModule({ stateFactory: true }, module)
export default class DocumentModule extends InjectVuexModule {
    @Resolve
    enrollment!: EnrollmentModule;

    @Resolve
    edgeDocumentsApi!: EdgeDocumentsApi;

    @Resolve
    patient!: PatientModule;

    public hasDocument = false;
    public pdfData = {
        product: '',
        firstName: '',
        middleInitial: '',
        lastName: '',
        phone: '',
        dob: '',
        gender: '',
        address1: '',
        address2: '',
        zip: '',
        city: '',
        state: '',
        prescriptionInsurance: false,
        insuranceProvider: '',
        policyNumber: '',
        insuranceCoverage: '',
        totalCost: '',
        remainingCost: '',
        pharmacy: '',
        quantity: '',
        supply: '',
        signature: '',
    };

    public products = products;

    @Action({ rawError: true })
    clear() {
        this.updateDocumentStatus(false);
    }

    @Action({ rawError: true })
    setDocumentStatus(newDocumentStatus: boolean) {
        this.hasDocument = newDocumentStatus;
    }

    @Mutation
    updatePDFData(pdfData: typeof DocumentModule.prototype.pdfData) {
        this.pdfData = pdfData;
    }

    @Action({ rawError: true })
    public async fetchDocument() {
        const { firstName, lastName, middleInitial } = this.pdfData;

        const isHarvoniEpclusa = [
            "Authorized Generic of EPCLUSA<sup>\u00AE</sup> (sofosbuvir 400 mg/velpatasvir 100 mg) tablets",
            "Authorized Generic of HARVONI<sup>\u00AE</sup> (ledipasvir 90 mg/ sofosbuvir 400 mg) tablets"
        ].includes(this.pdfData.product);

        const isVeclury = [
            "VEKLURY<sup>\u00AE</sup> (remdesivir) 100 mg for injection"
        ].includes(this.pdfData.product);

        // removing html tags for human readability on PDF
        const product = this.pdfData.product.replace(/<[^>]*>/g, '') || ''
        const fileData = [
            { name: 'memberNumber', value: this.enrollment.demographicInformation.memberNumber || '' },
            { name: 'product', value: product },
            { name: 'lastName', value: capitalize(lastName) || '' },
            { name: 'firstName', value: capitalize(firstName) || '' },
            { name: 'middleInitial', value: capitalize(middleInitial) || '' },
            { name: 'phone', value: this.pdfData.phone || '' },
            { name: 'dob', value: this.pdfData.dob || '' },
            { name: 'address1', value: this.pdfData.address1 || '' },
            { name: 'address2', value: this.pdfData.address2 || '' },
            { name: 'city', value: capitalize(this.pdfData.city) || '' },
            { name: 'state', value: this.pdfData.state || '' },
            { name: 'zip', value: this.pdfData.zip || '' },
            { name: 'prescriptionInsurance', value: this.pdfData.prescriptionInsurance ? 'Yes' : 'No' },
            { name: 'insuranceProvider', value: this.pdfData.insuranceProvider || '' },
            { name: 'policyNumber', value: this.pdfData.policyNumber || '' },
            { name: 'entireCost', value: this.pdfData.insuranceCoverage === 'all' ? this.pdfData.totalCost : '' },
            { name: 'remainingCost', value: this.pdfData.insuranceCoverage === 'some' ? this.pdfData.remainingCost : '' },
            { name: 'zeroCost', value: this.pdfData.insuranceCoverage === 'none' ? ( isVeclury ? this.pdfData.remainingCost : this.pdfData.totalCost) : '' },
            { name: 'fullName', value: capitalize(`${firstName}${middleInitial ? ` ${middleInitial}` : ''} ${lastName}`) },
            { name: 'currentDate', value: moment().format('L') },
            { name: 'signature', value: this.pdfData.signature }
        ];

        if(!isVeclury){
            fileData.push({ name: 'gender', value: this.pdfData.gender });
            fileData.push({ name: 'pharmacy', value: this.pdfData.pharmacy });
            fileData.push({ name : 'quantity', value: this.pdfData.quantity || ''});
            fileData.push({ name: 'supply', value: this.pdfData.supply || '' });
        }


        let formName = 'gilead_copay_rebate_form.pdf';

        if(isVeclury){
            formName = 'gilead_veclury_copay_rebate_form.pdf';
        }

        if(isHarvoniEpclusa){
            formName = 'harvoni_epclusa_copay_rebate_form.pdf';
        }

        // Below included to allow testing from localhost.
        const url = window.location.href.includes('localhost')
            ? 'https://portal-qa.trialcard.com/reimbursement/patient-information'
            // for checking into PR env
            // ? 'https://portal-pr.trialcard.com/27717/reimbursement/patient-information'
            : window.location.href;
        const document = await this.edgeDocumentsApi.fillablePdfGenerateFillablePdf({
            fileUrl: url.includes('patient-information') ? url.replace(/patient-information\/?/, formName) : url.replace(/summary\/?/, formName),
            outputFileName: 'copay-card',
            fileData,
        });

        return document;
    }

    async base64ToArrayBuffer(data: string) {
        const input = data.substring(data.indexOf(',') + 1);
        const binaryString = window.atob(input);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    saveByteArray(reportName: string, bytes: Uint8Array) {
        const blob = new Blob([bytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const fileName = reportName;
        link.download = fileName;
        link.click();
    }

    createBlobForUpload(reportName: string, pdfFile: Uint8Array) {
        const fileBlob = new Blob([pdfFile.buffer], { type: 'applicaton/pdf'})
        const file = new File([fileBlob], 'reimbursement-form.pdf', { type: 'application/pdf', lastModified: Date.now() })
        // this.enrollment.addReimbursementForm({ file, docType: PlpDocType['Claim']})
        const fileWithUrl = { blob: '', file, headers: {} }
        fileWithUrl.blob = URL.createObjectURL(file)
        this.enrollment.addReimbursementForm(fileWithUrl)
    }

    async createBlobForMergedFiles(reportName: string, pdfFile: Uint8Array) {
        const fileBlob = new Blob([pdfFile.buffer], { type: 'applicaton/pdf'})
        const file = new File([fileBlob], 'reimbursement-form.pdf', { type: 'application/pdf', lastModified: Date.now() })
        const mergedPdf = { file, docType: PlpDocType['Claim']}
        return mergedPdf
    }

    @Mutation
    updateDocumentStatus(newDocumentStatus: boolean) {
        this.setDocumentStatus(newDocumentStatus);
    }

    @Mutation
    updatePatientSignature(signature: string){
        this.pdfData.signature = signature
    }
}
