



































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Resolve } from 'vue-di';
import { faPrescription, faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { mapKeys } from 'lodash';
import IRIInfo from '~/components/iri-content/iri-info.vue';
import theme from '~/settings/theme';
import SessionModule from '~/store/session';
import LayoutModule from '~/store/layout';
import IriModule from '~/store/iri';

@Component<FloatingFooter>({
    components: {
        iriInfo: IRIInfo,
    },
    icons: {
        faPrescription,
        faChevronUp,
        faChevronDown,
    },
})
export default class FloatingFooter extends Vue {
    @Resolve
    public iri!: IriModule;

    public win = {
        h: 0,
        y: 0,
        x: 0,
    };

    public doc = {
        h: 0,
        w: 0,
    };

    public placeHolder = {
        h: 0,
        w: 0,
    };

    public get iriOpen() {
        return this.iri.open;
    }

    public set iriOpen(v) {
        this.iri.toggleIriOpen(v);
    }

    public get aboveTheFold() {
        return this.win.y + this.win.h < this.doc.h - this.placeHolder.h;
    }

    public get brkPoints() {
        return {
            'brk-xs': this.$vuetify.breakpoint.xs,
            'brk-xs-only': this.$vuetify.breakpoint.xsOnly,
            'brk-sm': this.$vuetify.breakpoint.sm,
            'brk-sm-only': this.$vuetify.breakpoint.smOnly,
            'brk-sm-and-down': this.$vuetify.breakpoint.smAndDown,
            'brk-sm-and-up': this.$vuetify.breakpoint.smAndUp,
            'brk-md': this.$vuetify.breakpoint.md,
            'brk-md-only': this.$vuetify.breakpoint.mdOnly,
            'brk-md-and-down': this.$vuetify.breakpoint.mdAndDown,
            'brk-md-and-up': this.$vuetify.breakpoint.mdAndUp,
            'brk-lg': this.$vuetify.breakpoint.lg,
            'brk-lg-only': this.$vuetify.breakpoint.lgOnly,
            'brk-lg-and-down': this.$vuetify.breakpoint.lgAndDown,
            'brk-lg-and-up': this.$vuetify.breakpoint.lgAndUp,
            'brk-xl': this.$vuetify.breakpoint.xl,
            'brk-xl-only': this.$vuetify.breakpoint.xlOnly,
        };
    }

    public onScroll() {
        this.win = {
            h: window.innerHeight,
            x: window.innerHeight,
            y: window.scrollY,
        };
        const footer = document.querySelector('#main-footer') as HTMLElement | null;
        const doc = document.body as HTMLElement;

        if (footer) {
            this.placeHolder = {
                h: footer.offsetHeight,
                w: footer.offsetWidth,
            };
        }
        this.doc = {
            h: doc.offsetHeight,
            w: doc.offsetWidth,
        };
    }

    public onResize() {
        this.win = {
            h: window.innerHeight,
            x: window.innerHeight,
            y: window.scrollY,
        };
        const footer = document.querySelector('#main-footer') as HTMLElement | null;
        const doc = document.body as HTMLElement;
        if (footer) {
            this.placeHolder = {
                h: footer.offsetHeight,
                w: footer.offsetWidth,
            };
        }
        this.doc = {
            h: doc.offsetHeight,
            w: doc.offsetWidth,
        };
    }
}
