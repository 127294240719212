const middleware = {}

middleware['globaliri'] = require('..\\middleware\\globaliri.ts')
middleware['globaliri'] = middleware['globaliri'].default || middleware['globaliri']

middleware['hideiri'] = require('..\\middleware\\hideiri.ts')
middleware['hideiri'] = middleware['hideiri'].default || middleware['hideiri']

middleware['session'] = require('..\\middleware\\session.ts')
middleware['session'] = middleware['session'].default || middleware['session']

middleware['showiri'] = require('..\\middleware\\showiri.ts')
middleware['showiri'] = middleware['showiri'].default || middleware['showiri']

export default middleware
